*, body {
  @include nexa-light;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $black;
  text-decoration: none;

    &:focus {
      outline: none;
    }
}

button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6 {
  @include garamond-regular-italic;
  line-height: normal;
  margin: 0;
}

p {
  font-size: 14px;
  letter-spacing: 0.03em;
}

.cms-page .strong, .cms-lateral-margin .strong {
    @include nexa-bold;
  }

.uppercase{
  text-transform: uppercase;
}

.light-blue {
  color: $light-blue !important;
}

.list-unstyled {
  list-style: none;
}

.relative {
  position: relative;
}

.txt-center {
  text-align: center;
}

.width-100 {
  width: 100%;
}

.border {
  border: 1px solid $line-color;
}

.inline-block {
  display: inline-block;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.text-capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.red {
  color: $red;
}

.bold {
  font-weight: 700;
}


// spacing
.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-top-10 {
  margin-top: 10px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-40-vert {
  margin: 40px 0;
}

.padding-top-50{
  padding-top: 50px;
}
.padding-bottom-50{
  padding-bottom: 50px;
}

.padding-30-0{
  padding: 30px 0px;
}
.bg-red{
  background-color: $red;
}