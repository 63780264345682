.profile-container {
    padding: 95px 0 60px;
    @include breakpoint(small down) {
        padding: 0 0 60px;
    }
}

.profile-nav {
    a {
        @include nexa-bold;
        @include transition-basic;
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.1em;
        margin: 0px 0;
        color: $text-gray;
        background-color: white;
        padding: 8px;
        @include breakpoint(small down) {
            margin: 10px 0;
        }

        &.active,
        &:hover {
            color: white;
            background-color: $black;
            padding: 8px;
        }
    }
}

.profile-title {
    @include garamond-regular-italic;
    font-size: 28px;
    margin: 25px 0 5px;
    padding-bottom: 45px;
    @include breakpoint(small down) {
        font-size: 24px;
        padding-bottom: 0;
    }
}

.profile-separator {
    border: none;
    border-top: 1px solid $line-color;
    margin-top: 40px;
}

.responsive-table {
    overflow-x: auto;
}

.verified-icon {
    position: absolute;
    top: 27px;
    right: 20px;
    height: 22px;
}

.table-orders {
    border-collapse: collapse;
    min-width: 650px;

    th {
        text-align: left;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.1em;
        padding-bottom: 4px;
    }
}

.margin-bottom-mobile {
    @media screen and (max-width: 39.9375em) {
        margin-bottom: 50px !important;
    }
}

.order-row {
    td {
        border-bottom: 1px solid $line-color;
        border-top: 1px solid $line-color;
        padding: 10px 0 8px;
        font-size: 14px;
        letter-spacing: 0.05em;
    }
}

.table-orders:not(.ecredit-detail-table) .order-row {
    @include transition-basic;
    cursor: pointer;

    &:hover,
    &.selected {
        background-color: $line-color;
    }
}

.order-row-indicator-container {
    width: 3%;
    position: relative;
}

.order-controls-row {
    margin-bottom: 10px;
}

.order-row-indicator {
    @include transition-basic;
    width: 8px;
    position: absolute;
    top: 13px;
    left: 4px;

    &.fa-rotate-90 {
        transform: rotate(90deg);
    }
}

.order-controls {
    display: none;
    background-color: #f9f9f9;

    > td {
        padding: 20px 30px;
    }

    h4 {
        @include nexa-bold;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 0.1em;
        margin-bottom: 8px;
    }
}

.dispatch-data-row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin: 2px 0;

    > div {
        flex: 1;
        font-size: 14px;
        letter-spacing: 0.05em;
    }

    .label {
        font-size: 12px;
        text-transform: uppercase;
    }
}

.order-controls-return {
    border-collapse: collapse;

    td {
        border-top: 1px solid $line-color;
        padding: 8px;
        line-height: 1.43;
        vertical-align: middle;
    }

    .product-sku {
        font-size: 12px;
        letter-spacing: 0.1em;
        margin-top: 5px;
    }
}

.new-return-request form {
    margin: 12px 0;
}

.new-return-request {
    display: none;
}

.returned-goods-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    div {
        font-size: 14px;
        letter-spacing: 0.05em;
    }
}

.returned-goods-item {
    border-bottom: 1px solid $line-color;
    padding: 15px 0;
}

.cancel-return-request-form {
    @include breakpoint(small down) {
        margin-top: 20px;
    }
}

.ecredit-detail-table {
    .id {
        padding-right: 10px;
    }
}

.order-row .pay-subscription {
    padding-top: 0;
    padding-bottom: 0;
}

.subscription-type-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
