.main-menu {

  ul {
    list-style: none;
  }

  > ul > li {
    display: inline-block;
    margin-right: -4px;

    > a {
      @include garamond-semibold-italic;
      padding: 0 16px 5px;
      letter-spacing: .02em;
      display: inline-block;
      position: relative;
      z-index: 11;
      @media only screen and (max-width: 1087px) {padding: 0 12px 5px;}

    /*
      &::after {
        content: '';
        opacity: 1;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -2px;
        height: 3px;
        background-color: $light-blue;
      }
      */
    }
    a.new-arrivals::after, a.special-offer::after {
      content: '';
      opacity: 1;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -2px;
      height: 3px;
      background-color: $black-hover;
    }
    a.special-offer::after{
      background-color:$light-blue;
    }

    &:hover {
/*
      a::after {
        opacity: 1;
      }
      */

      .secondary-menu {
        opacity: 1;
        display: block;
        visibility: visible;
        justify-content: center;
        transition-delay: .5s;
        box-shadow: 0 15px 10px -15px #111;
      }

    }
  }
}

.secondary-menu {
  max-width: 1170px;
  border: 1px solid #e3e6ea;
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  left: 0;
  right: 0;
  margin: 11px auto;
  margin-top: 0;
  z-index: 7;

    ul > li > a {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      line-height: 1.2;
      display: block;
      padding:4px 2px;
    }
    .small-12.medium-2.columns.padding-secondary_menu{
      padding:0px;
    }
}


.menu-side-img {

    a {
      display: block;
    }

    span {
      @include garamond-regular-italic;
      display: block;
      font-size: 18px;
      letter-spacing: 0.01em;
      margin: 12px 0 5px;
    }
}

.menu-big-img {
  text-align: center;

    a {
      display: block;
      margin-bottom: 30px;
    }

    span {
      @include garamond-regular-italic;
      display: block;
      font-size: 18px;
      letter-spacing: 0.01em;
      margin: -6px 0 12px;
    }
}

.secondary-menu__title,
.secondary-menu__title a {
  @include nexa-bold;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
}



//mobile menu
.mobile-menu-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: $black;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.mobile-menu-banner__icon {
  width: 27px;
  margin: 0 10px;
}

.cart-mobile {

    img {
      width: 27px !important;
      margin: 0 10px;
      position: static !important;
    }

    .cart-dot {
      top: -1px !important;
      right: 4px;
    }
}

.mobile-menu-banner__trigger {
  width: 100px;

    img {
      width: 45px;
    }

    .hidden {
      display: none;
    }
}

.mobile-menu {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  height: 100vh;
  border-bottom: 1px solid white;
  display: none;
}

.mobile-menu__header {
  display: flex;
  padding: 10px 0 9px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $line-color;

    img {
      width: 44px;
    }
}

.mobile-menu__search {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 57px;
  z-index: 1000;
  display: none;

    img {
      width: 13px;
      position: absolute;
      top: 16px;
      left: 21px;
    }

    input {
      margin-bottom: 0;
      background-color: $black;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.05em;
      height: 46px;
      border: none;
      border-bottom: 1px solid white;
      padding: 5px 0 4px 40px;

        &:focus {
          background-color: $black;
          border: none;
          box-shadow: none;
          border-bottom: 1px solid white;
        }
    }
}

.mobile-menu__content {
  background-image: url("/skins/current-skin/images/header/menu-mobile-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 0px 30px 0px 30px;
}

.mobile-menu__content-scroll {
  max-height: calc(100vh - 156px);
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.mobile-menu__row {
  display: flex;
  margin: 8px 0;
}

.mobile-menu__col-left {
  width: 30%;
  text-align: right;

    img {
      width: 26px;
      position: relative;
      top: -1px;
    }

    span {
      text-transform: uppercase;
      font-size: 10px;
      background-color: $black;
      font-weight: bold;
      color: white;
      display: inline-block;
      padding: 4px 8px 2px;
      line-height: 1;

        &.blue {
          background-color: $light-blue;
        }
    }
}

.mobile-menu__col-right {
  width: 70%;
  padding-left: 9px;

  .mobile_text_menu {
  @include garamond-regular-italic;
  display: block;
  font-size: 18px;
  letter-spacing: 0.01em;
  margin: 6px 0;
  }

  input {
    border:0px none;
    background: transparent;
  }

}

.mobile-menu__link-upper {
  @include nexa-bold;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 0.05em;
  display: block;
  padding-top: 3px;

  &.dropshipping {
      background-color: $light-blue;
      color: $gray;
      padding: 2px 16px;
      width: max-content;
  }
}

.mobile-menu__link-coursive, .mobile-menu__link-coursive a {
  @include garamond-regular-italic;
  display: block;
  font-size: 18px;
  letter-spacing: 0.01em;
  margin: 6px 0;
}

.mobile-menu__separator {
  margin: 15px 0;
  border: none;
  border-bottom: 1px solid #c7c8cc;
}

.mobile-menu__language {
  position: relative;
  display: inline-block;

    &::after {
      content: '';
      position: absolute;
      top: 9px;
      right: -16px;
      width: 12px;
      height: 7px;
      background-image: url("/skins/current-skin/images/header/arrow-language.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
    }

    + ul {
      display: none;
    }
}

.mobile-menu__footer {
  text-align: center;
  font-size: 10px;
  padding: 30px 0 0px 15px;
  text-transform: uppercase;

    b {
      @include nexa-bold;
    }

}

.information-policy{
  @include nexa-bold;
  text-align: center;
  padding: 0px 5px 15px
}