.home-hero__image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  @include breakpoint(small down) {
    height: 200px;
  }
}

.slider_full__image {

  @media screen and (max-width: 39.9375em) {
    height: calc(100vh - 121px);
  }  
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    height: calc(100vh - 117px);
  }
  @media screen and (min-width: 64em) {
    height: calc(100vh - 144.09px);
  }

  img {

    object-fit: cover;
    object-position: top;

    @media screen and (max-width: 39.9375em) {
      height: calc(100vh - 121px);
    }  
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      height: calc(100vh - 117px);
    }
    @media screen and (min-width: 64em){
      height: calc(100vh - 144.09px);
    }

    width: 100%;

  }

}

.slider-block {
  width: 100%;
  display: inline-block;
  @media screen and (max-width: 39.9375em) {
    padding: 10px;
    background: #272727;
    h2 {color: white;}
  }  
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    padding: 20px;
    background: #272727;
    h2 {color: white;}
  }
  @media screen and (min-width: 64em) {
    padding: 30px 30px 0px 30px;
  }
  text-align: center;
}

#section1 {

  .slick-dots {
    @media screen and (max-width: 63.9375em) {
      z-index: 2;
      bottom: auto !important;
      margin-left: -45vw !important;
      margin-top: calc(50vh - 72px) !important; 
      transform: rotate(90deg);
      top: 0;
    }
  }

}

.slider-title {

  @media screen and (max-width: 39.9375em) {
    font-size: 26px;
  }  
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    font-size: 40px;
  }
  @media screen and (min-width: 64em) {
    font-size: 50px;
  }
  margin-bottom: 20px;
}

.slider_btn {
  width: 70%;
  margin: auto;
  margin-bottom: 30px;
  @include breakpoint(small down) {
    margin-bottom: 0px;
  }
}

.slider_block {

  position: absolute !important;
  z-index: 6;

  @media screen and (min-width: 64em) {
    //margin-top: 15%;
    margin-left: 60%;
    background: #272727;
    min-width: 400px;
    width: 30%;
  }

  @media screen and (max-width: 63.9375em) {
    left: 50%;
    transform: translateX(-50%);
    bottom: 137px;
  }

  @media screen and (max-width: 39.9375em) {
    min-width: 260px;
    width: 70%;
    background: #272727;
  }  
  @media screen and (min-width: 40em) and (max-width: 63.9375em) {
    min-width: 400px;
    width: 50%;
    background: #272727;
  }

  .slider_text {

    h2, p {
    color: #ffffff;
    }

    @media screen and (max-width: 39.9375em) {
      background: #272727;
    }  
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {
      background: #272727;
    }
    @media screen and (min-width: 64em) {
      background: #272727;
    }
  
  }

}

.landscape {

  @media screen and (max-width: 39.9375em) {
    display: none !important;
  } 

}

.portrait {

  @media screen and (min-width: 40em) {
    display: none !important;
  } 

}

.home-hero__text {
  padding: 10vw 5vw;
  text-align: center;
  @include breakpoint(small down) {
    padding: 25px 15px 30px;
    border: 1px solid $line-color;
    margin: -40px 15px 0;
    background-color: white;
  }
  

    h2 {
      font-size: 50px;
      @include breakpoint(medium down) {
        font-size: 44px;
        line-height: 48px;
      }
      @include breakpoint(small down) {
        font-size: 30px;
        line-height: normal;
      }
    }

    p {
      padding: 25px 0 40px;
      line-height: 1.7;
      @include breakpoint(medium down) {
        padding: 20px 0;
      }
      @include breakpoint(small down) {
        padding: 10px 0 20px;
      }
    }
}

.page-title {
  text-align: center;
  padding: 0 0 5px;
}

.home-2col-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  position: relative;
  padding: 23px 30px 40px;
  @include breakpoint(small down) {
    padding: 20px 15px 30px;
  }
 
    /*&::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      //background-color: $black;)
      opacity: .8;
    }*/

    
    > div {
      z-index: 1;
    }

    h2 {
      font-size: 40px;
      color: white;
      @include breakpoint(small down) {
        font-size: 30px;
      }
    }

    p {
      color: white;
      padding: 20px 0 28px;
      @include breakpoint(small down) {
        padding: 10px 0 18px;
      }
    }
}

.home-logos {
  padding: 0 10px;
  @include breakpoint(medium down) {
    padding: 0 30px;
    margin-bottom: 20px;
  }
  @include breakpoint(small down) {
    padding: 0 15px;
  }
}

.home-brands {
  text-align: center;
  padding: 0 15px;
  @include breakpoint(small down) {
    padding: 0 5px;
  }

    a {
      display: block;
    }

    img {
      width: 100%;
    }

    h2 {
      margin-top: 15px;
      @include breakpoint(small down) {
        margin: 10px 0 18px;
        font-size: 22px;
      }
    }
}

.home-categories {
  margin-bottom: 40px;

    img {
      width: 100%;
    }
}

.home-categories__text {
  padding: 22px 20px 30px;
  max-width: 400px;
  margin: -40px auto 0;
  background-color: white;
  border: 1px solid $line-color;
  z-index: 1;
  position: relative;
  text-align: center;
  /*@include breakpoint(small down) {
    margin: -40px 15px 0;
  }*/

    h2 {
      font-size: 27px;
      letter-spacing: 0.02em;
      margin-bottom: 15px;
    }

    a {
      margin: 0 15px;
      @include breakpoint(small down) {
        margin: 0 8px;
      }
    }
}

.home-icons {
  background-color: $black;
  text-align: center;
  padding: 40px 0 34px;

    h3 {
      @include nexa-bold;
      color: white;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.05em;
      margin: 10px 0 18px;
    }

    p {
      color: white;
      font-size: 13px;
    }

    img {
      width: 40px;
    }

}

.home-icons-bg-white{
  background-color: white;
  text-align: center;
  padding: 40px 0 34px;

  h3 {
    @include nexa-bold;
    color: $black;;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.05em;
    margin: 10px 0 18px;
  }
  p {
    color: $black;;
    font-size: 13px;
  }

  img {
    width: 40px;
  }
}


.three-columns-icons{
  text-align: center;
  padding: 40px 0px 0px 0px;

    h3 {
      @include nexa-bold;
      color: $black;
      text-transform: uppercase;
      font-size: 20px;
      letter-spacing: 0.05em;
      margin: 45px 0 18px;
    }

    p {
      color: $black;
      font-size: 13px;
    }

    img {
      width: 150px;
    }

    &.two-columns{
      align-items: center;

      strong{
        @include nexa-bold;
      }

      h2 {
        @include garamond-regular-italic;
        color: $black;
        text-transform: uppercase;
        font-size: 50px;
        letter-spacing: 0.05em;
      }

      h3 {
        @include nexa-bold;
        color: $black;
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 0.05em;
        margin: 21px 0 18px;
      }
      img {
        width: 80%;
      }
    }
  }

.three-columns-icons .strong{
  @include nexa-bold;
}


.home-icons__item {
  @include breakpoint(small down) {
    margin: 20px 0;
  }
}

.home-cta {
  background-image: url('/skins/current-skin/images/home/home_sfondo-sezioni-scure-con-pulsante.jpg');
  background-position: top center;
  background-size:cover;
  text-align: center;
  padding: 35px 0px;
  @include breakpoint(medium down) {
    padding: 30px 10px;
  }

    span {
      @include nexa-bold;
      color: white;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.05em;
      margin-right: 40px;
      @include breakpoint(medium down) {
        margin: 0 0 15px;
        display: block;
      }
      @include breakpoint(small down) {
        margin: 0 0 20px;
      }
    }
}

.home-2col-space {
  margin: 30px 0;
}

.home-logos-space {
  margin: 50px 0px 50px;
}

.home-brands-space {
  margin: 30px 0 50px;
  @include breakpoint(small down) {
    margin: 30px 0px;
  }
}

.home-brands-space .row.column{
  @include breakpoint(small down) {
      margin:0px;
      padding:0px;
}

}

.home-icons-space {
  margin: 40px 0;
  @include breakpoint(small down) {
    margin: 15px 0 40px;
  }
}

/*
.home-2col-bg.-blue.second-col{
  @include breakpoint(small down) {
    margin: 30px 0px 30px 0px;
    padding: 0px 15px 0px;
  }
}
*/


.button_cta_space{
  padding: 20px 0px 0px 0px;
}


.small-12.medium-6.columns.-black.two-column-cta {
  @include breakpoint(small down) {
    margin-bottom:20px;
  }
}

