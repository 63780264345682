.footer-logo {
  text-align: center;
  border-top: 1px solid $line-color;
  border-bottom: 1px solid $line-color;
  padding: 32px 0 24px;
  background-image: url('/skins/current-skin/images/footer/footer_sfondo-sezione-logo.jpg');
  background-position: center center;
  background-size: cover;


    img {
      width: 300px;
    }

    p {
      max-width: 670px;
      margin: 30px auto 0;
    }
}

.footer-links {
  text-align: center;
  padding: 28px 0 25px;
  @include breakpoint(small down) {
    padding: 5px 0 35px;
  }

    h4 {
      @include nexa-bold;
      letter-spacing: .15em;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 15px;
      @include breakpoint(small down) {
        margin: 30px 0 5px;
      }
    }

    ul {
      list-style: none;
    }

    a {
      @include transition-basic;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.03em;

        &:hover {
          color: $light-blue;
        }
    }
}

.footer-icons {
  background-color: $black;
  text-align: center;
  padding: 20px 0 24px;
  @include breakpoint(small down) {
    padding: 0 0 40px;
  }

    span {
      color: white;
      display: block;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: .15em;
      @include breakpoint(medium down) {
        font-size: 9px;
      }
    }

    .footer-icons__title {
      @include nexa-bold;
      font-size: 13px;
    }

    img {
      width: 35px;
      margin-bottom: 5px;
      @include breakpoint(small down) {
        margin: 35px 0 5px;
      }
    }

    h5 {
      color: white;
      font-size: 19px;
      margin: 12px 0 16px;
    }
}

.footer-payments {
  background-color: $black;
  text-align: center;
  /*border-bottom: 1px solid $line-color;*/
  padding: 0px 0 32px;
  @include breakpoint(medium down) {
    padding: 0px 0 28px;
  }

    h4 {
      @include nexa-bold;
      letter-spacing: .15em;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 25px;
    }
}

.footer-payments__logos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  filter: grayscale(100%);

    img {
      margin: 0 10px;
      @include breakpoint(medium down) {
        margin: 0 8px 10px;
      }
    }
}

.footer-social {
  background-color: $black;
  padding: 28px 0 30px;
  text-align: center;

    h4 {
      @include nexa-bold;
      letter-spacing: .15em;
      font-size: 13px;
      text-transform: uppercase;
      margin-bottom: 25px;
      color: white;
    }

    img {
      width: auto;
      height: 25px;
      margin: 0 10px;
    }
}

.footer-social__flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom {
  text-align: center;
  padding: 11px 0 10px;

  @include breakpoint(medium down) {
    margin-bottom: 55px;
  }

    p {
      letter-spacing: 0.1em;
      text-transform: uppercase;
      font-size: 11px;
    }

    a {
      @include nexa-bold;
    }

    .footer-data-space{
      margin-top:10px;
    }
}

.cookie_policy {
  text-align: center;
  padding: 11px 0 10px;
  font-weight: 100;
  text-transform: uppercase;
  font-size: 12px;
  

    a {
      @include nexa-light;
      
    }
}

.divisore-footer{
  border: 1px solid $black;
  height: 9px;
  display: inline-block;

  &.black{
  border: 1px solid $black;
}

}