$xxs: 8px;
$xs: 16px;
$s: 24px;
$m: 32px;
$l: 40px;
$xl: 48px;
$xxl: 56px;
$xxxl: 64px;

// MARGIN
.margin-0 {
	margin: 0;
}
.mt-0 {
	margin-top: 0;
}
.mb-0 {
	margin-bottom: 0;
}
.ml-0 {
	margin-left: 0;
}
.mr-0 {
	margin-right: 0;
}
.mh-0 {
	margin-left: 0;
	margin-right: 0;
}
.mv-0 {
	margin-top: 0;
	margin-bottom: 0;
}

.margin-0-tablet {
	@include breakpoint (medium down) {
		margin: 0;
	}
}
.mt-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
	}
}
.mb-0-tablet {
	@include breakpoint (medium down) {
		margin-bottom: 0;
	}
}
.ml-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
	}
}
.mr-0-tablet {
	@include breakpoint (medium down) {
		margin-right: 0;
	}
}
.mh-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
		margin-right: 0;
	}
}
.mv-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.margin-0-mobile {
	@include breakpoint (small down) {
		margin: 0;
	}
}
.mt-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
	}
}
.mb-0-mobile {
	@include breakpoint (small down) {
		margin-bottom: 0;
	}
}
.ml-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
	}
}
.mr-0-mobile {
	@include breakpoint (small down) {
		margin-right: 0;
	}
}
.mh-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
		margin-right: 0;
	}
}
.mv-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.margin-xxs {
	margin: $xxs;
}
.mt-xxs {
	margin-top: $xxs;
}
.mb-xxs {
	margin-bottom: $xxs;
}
.ml-xxs {
	margin-left: $xxs;
}
.mr-xxs {
	margin-right: $xxs;
}
.mh-xxs {
	margin-left: $xxs;
	margin-right: $xxs;
}
.mv-xxs {
	margin-top: $xxs;
	margin-bottom: $xxs;
}

.margin-xxs-tablet {
	@include breakpoint (medium down) {
		margin: $xxs;
	}
}
.mt-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
	}
}
.mb-xxs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxs;
	}
}
.ml-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
	}
}
.mr-xxs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxs;
	}
}
.mh-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
.mv-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}

.margin-xxs-mobile {
	@include breakpoint (small down) {
		margin: $xxs;
	}
}
.mt-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
	}
}
.mb-xxs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxs;
	}
}
.ml-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
	}
}
.mr-xxs-mobile {
	@include breakpoint (small down) {
		margin-right: $xxs;
	}
}
.mh-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
.mv-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}

.margin-xs {
	margin: $xs;
}
.mt-xs {
	margin-top: $xs;
}
.mb-xs {
	margin-bottom: $xs;
}
.ml-xs {
	margin-left: $xs;
}
.mr-xs {
	margin-right: $xs;
}
.mh-xs {
	margin-left: $xs;
	margin-right: $xs;
}
.mv-xs {
	margin-top: $xs;
	margin-bottom: $xs;
}

.margin-xs-tablet {
	@include breakpoint (medium down) {
		margin: $xs;
	}
}
.mt-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
	}
}
.mb-xs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xs;
	}
}
.ml-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
	}
}
.mr-xs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xs;
	}
}
.mh-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
.mv-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}

.margin-xs-mobile {
	@include breakpoint (small down) {
		margin: $xs;
	}
}
.mt-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
	}
}
.mb-xs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xs;
	}
}
.ml-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
	}
}
.mr-xs-mobile {
	@include breakpoint (small down) {
		margin-right: $xs;
	}
}
.mh-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
.mv-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}

.margin-s {
	margin: $s;
}
.mt-s {
	margin-top: $s;
}
.mb-s {
	margin-bottom: $s;
}
.ml-s {
	margin-left: $s;
}
.mr-s {
	margin-right: $s;
}
.mh-s {
	margin-left: $s;
	margin-right: $s;
}
.mv-s {
	margin-top: $s;
	margin-bottom: $s;
}

.margin-s-tablet {
	@include breakpoint (medium down) {
		margin: $s;
	}
}
.mt-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
	}
}
.mb-s-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $s;
	}
}
.ml-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
	}
}
.mr-s-tablet {
	@include breakpoint (medium down) {
		margin-right: $s;
	}
}
.mh-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
		margin-right: $s;
	}
}
.mv-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}

.margin-s-mobile {
	@include breakpoint (small down) {
		margin: $s;
	}
}
.mt-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
	}
}
.mb-s-mobile {
	@include breakpoint (small down) {
		margin-bottom: $s;
	}
}
.ml-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
	}
}
.mr-s-mobile {
	@include breakpoint (small down) {
		margin-right: $s;
	}
}
.mh-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
		margin-right: $s;
	}
}
.mv-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}

.margin-m {
	margin: $m;
}
.mt-m {
	margin-top: $m;
}
.mb-m {
	margin-bottom: $m;
}
.ml-m {
	margin-left: $m;
}
.mr-m {
	margin-right: $m;
}
.mh-m {
	margin-left: $m;
	margin-right: $m;
}
.mv-m {
	margin-top: $m;
	margin-bottom: $m;
}

.margin-m-tablet {
	@include breakpoint (medium down) {
		margin: $m;
	}
}
.mt-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
	}
}
.mb-m-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $m;
	}
}
.ml-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
	}
}
.mr-m-tablet {
	@include breakpoint (medium down) {
		margin-right: $m;
	}
}
.mh-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
		margin-right: $m;
	}
}
.mv-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}

.margin-m-mobile {
	@include breakpoint (small down) {
		margin: $m;
	}
}
.mt-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
	}
}
.mb-m-mobile {
	@include breakpoint (small down) {
		margin-bottom: $m;
	}
}
.ml-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
	}
}
.mr-m-mobile {
	@include breakpoint (small down) {
		margin-right: $m;
	}
}
.mh-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
		margin-right: $m;
	}
}
.mv-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}

.margin-l {
	margin: $l;
}
.mt-l {
	margin-top: $l;
}
.mb-l {
	margin-bottom: $l;
}
.ml-l {
	margin-left: $l;
}
.mr-l {
	margin-right: $l;
}
.mh-l {
	margin-left: $l;
	margin-right: $l;
}
.mv-l {
	margin-top: $l;
	margin-bottom: $l;
}

.margin-l-tablet {
	@include breakpoint (medium down) {
		margin: $l;
	}
}
.mt-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
	}
}
.mb-l-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $l;
	}
}
.ml-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
	}
}
.mr-l-tablet {
	@include breakpoint (medium down) {
		margin-right: $l;
	}
}
.mh-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
		margin-right: $l;
	}
}
.mv-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}

.margin-l-mobile {
	@include breakpoint (small down) {
		margin: $l;
	}
}
.mt-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
	}
}
.mb-l-mobile {
	@include breakpoint (small down) {
		margin-bottom: $l;
	}
}
.ml-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
	}
}
.mr-l-mobile {
	@include breakpoint (small down) {
		margin-right: $l;
	}
}
.mh-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
		margin-right: $l;
	}
}
.mv-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}

.margin-xl {
	margin: $xl;
}
.mt-xl {
	margin-top: $xl;
}
.mb-xl {
	margin-bottom: $xl;
}
.ml-xl {
	margin-left: $xl;
}
.mr-xl {
	margin-right: $xl;
}
.mh-xl {
	margin-left: $xl;
	margin-right: $xl;
}
.mv-xl {
	margin-top: $xl;
	margin-bottom: $xl;
}

.margin-xl-tablet {
	@include breakpoint (medium down) {
		margin: $xl;
	}
}
.mt-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
	}
}
.mb-xl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xl;
	}
}
.ml-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
	}
}
.mr-xl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xl;
	}
}
.mh-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
.mv-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}

.margin-xl-mobile {
	@include breakpoint (small down) {
		margin: $xl;
	}
}
.mt-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
	}
}
.mb-xl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xl;
	}
}
.ml-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
	}
}
.mr-xl-mobile {
	@include breakpoint (small down) {
		margin-right: $xl;
	}
}
.mh-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
.mv-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}

.margin-xxl {
	margin: $xxl;
}
.mt-xxl {
	margin-top: $xxl;
}
.mb-xxl {
	margin-bottom: $xxl;
}
.ml-xxl {
	margin-left: $xxl;
}
.mr-xxl {
	margin-right: $xxl;
}
.mh-xxl {
	margin-left: $xxl;
	margin-right: $xxl;
}
.mv-xxl {
	margin-top: $xxl;
	margin-bottom: $xxl;
}

.margin-xxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxl;
	}
}
.mt-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
	}
}
.mb-xxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxl;
	}
}
.ml-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
	}
}
.mr-xxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxl;
	}
}
.mh-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
.mv-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}

.margin-xxl-mobile {
	@include breakpoint (small down) {
		margin: $xxl;
	}
}
.mt-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
	}
}
.mb-xxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxl;
	}
}
.ml-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
	}
}
.mr-xxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxl;
	}
}
.mh-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
.mv-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}

.margin-xxxl {
	margin: $xxxl;
}
.mt-xxxl {
	margin-top: $xxxl;
}
.mb-xxxl {
	margin-bottom: $xxxl;
}
.ml-xxxl {
	margin-left: $xxxl;
}
.mr-xxxl {
	margin-right: $xxxl;
}
.mh-xxxl {
	margin-left: $xxxl;
	margin-right: $xxxl;
}
.mv-xxxl {
	margin-top: $xxxl;
	margin-bottom: $xxxl;
}

.margin-xxxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxxl;
	}
}
.mt-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
	}
}
.mb-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxl;
	}
}
.ml-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
	}
}
.mr-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxl;
	}
}
.mh-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
.mv-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}

.margin-xxxl-mobile {
	@include breakpoint (small down) {
		margin: $xxxl;
	}
}
.mt-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
	}
}
.mb-xxxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxl;
	}
}
.ml-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
	}
}
.mr-xxxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxl;
	}
}
.mh-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
.mv-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}


// PADDING
.padding-0 {
	padding: 0;
}
.pt-0 {
	padding-top: 0;
}
.pb-0 {
	padding-bottom: 0;
}
.pl-0 {
	padding-left: 0;
}
.pr-0 {
	padding-right: 0;
}
.ph-0 {
	padding-left: 0;
	padding-right: 0;
}
.pv-0 {
	padding-top: 0;
	padding-bottom: 0;
}

.padding-0-tablet {
	@include breakpoint (medium down) {
		padding: 0;
	}
}
.pt-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
	}
}
.pb-0-tablet {
	@include breakpoint (medium down) {
		padding-bottom: 0;
	}
}
.pl-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
	}
}
.pr-0-tablet {
	@include breakpoint (medium down) {
		padding-right: 0;
	}
}
.ph-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
		padding-right: 0;
	}
}
.pv-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.padding-0-mobile {
	@include breakpoint (small down) {
		padding: 0;
	}
}
.pt-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
	}
}
.pb-0-mobile {
	@include breakpoint (small down) {
		padding-bottom: 0;
	}
}
.pl-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
	}
}
.pr-0-mobile {
	@include breakpoint (small down) {
		padding-right: 0;
	}
}
.ph-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
		padding-right: 0;
	}
}
.pv-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.padding-xxs {
	padding: $xxs;
}
.pt-xxs {
	padding-top: $xxs;
}
.pb-xxs {
	padding-bottom: $xxs;
}
.pl-xxs {
	padding-left: $xxs;
}
.pr-xxs {
	padding-right: $xxs;
}
.ph-xxs {
	padding-left: $xxs;
	padding-right: $xxs;
}
.pv-xxs {
	padding-top: $xxs;
	padding-bottom: $xxs;
}

.padding-xxs-tablet {
	@include breakpoint (medium down) {
		padding: $xxs;
	}
}
.pt-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
	}
}
.pb-xxs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxs;
	}
}
.pl-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
	}
}
.pr-xxs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxs;
	}
}
.ph-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
.pv-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}

.padding-xxs-mobile {
	@include breakpoint (small down) {
		padding: $xxs;
	}
}
.pt-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
	}
}
.pb-xxs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxs;
	}
}
.pl-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
	}
}
.pr-xxs-mobile {
	@include breakpoint (small down) {
		padding-right: $xxs;
	}
}
.ph-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
.pv-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}

.padding-xs {
	padding: $xs;
}
.pt-xs {
	padding-top: $xs;
}
.pb-xs {
	padding-bottom: $xs;
}
.pl-xs {
	padding-left: $xs;
}
.pr-xs {
	padding-right: $xs;
}
.ph-xs {
	padding-left: $xs;
	padding-right: $xs;
}
.pv-xs {
	padding-top: $xs;
	padding-bottom: $xs;
}

.padding-xs-tablet {
	@include breakpoint (medium down) {
		padding: $xs;
	}
}
.pt-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
	}
}
.pb-xs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xs;
	}
}
.pl-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
	}
}
.pr-xs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xs;
	}
}
.ph-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
.pv-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}

.padding-xs-mobile {
	@include breakpoint (small down) {
		padding: $xs;
	}
}
.pt-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
	}
}
.pb-xs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xs;
	}
}
.pl-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
	}
}
.pr-xs-mobile {
	@include breakpoint (small down) {
		padding-right: $xs;
	}
}
.ph-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
.pv-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}

.padding-s {
	padding: $s;
}
.pt-s {
	padding-top: $s;
}
.pb-s {
	padding-bottom: $s;
}
.pl-s {
	padding-left: $s;
}
.pr-s {
	padding-right: $s;
}
.ph-s {
	padding-left: $s;
	padding-right: $s;
}
.pv-s {
	padding-top: $s;
	padding-bottom: $s;
}

.padding-s-tablet {
	@include breakpoint (medium down) {
		padding: $s;
	}
}
.pt-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
	}
}
.pb-s-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $s;
	}
}
.pl-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
	}
}
.pr-s-tablet {
	@include breakpoint (medium down) {
		padding-right: $s;
	}
}
.ph-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
		padding-right: $s;
	}
}
.pv-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}

.padding-s-mobile {
	@include breakpoint (small down) {
		padding: $s;
	}
}
.pt-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
	}
}
.pb-s-mobile {
	@include breakpoint (small down) {
		padding-bottom: $s;
	}
}
.pl-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
	}
}
.pr-s-mobile {
	@include breakpoint (small down) {
		padding-right: $s;
	}
}
.ph-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
		padding-right: $s;
	}
}
.pv-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}

.padding-m {
	padding: $m;
}
.pt-m {
	padding-top: $m;
}
.pb-m {
	padding-bottom: $m;
}
.pl-m {
	padding-left: $m;
}
.pr-m {
	padding-right: $m;
}
.ph-m {
	padding-left: $m;
	padding-right: $m;
}
.pv-m {
	padding-top: $m;
	padding-bottom: $m;
}

.padding-m-tablet {
	@include breakpoint (medium down) {
		padding: $m;
	}
}
.pt-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
	}
}
.pb-m-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $m;
	}
}
.pl-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
	}
}
.pr-m-tablet {
	@include breakpoint (medium down) {
		padding-right: $m;
	}
}
.ph-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
		padding-right: $m;
	}
}
.pv-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}

.padding-m-mobile {
	@include breakpoint (small down) {
		padding: $m;
	}
}
.pt-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
	}
}
.pb-m-mobile {
	@include breakpoint (small down) {
		padding-bottom: $m;
	}
}
.pl-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
	}
}
.pr-m-mobile {
	@include breakpoint (small down) {
		padding-right: $m;
	}
}
.ph-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
		padding-right: $m;
	}
}
.pv-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}

.padding-l {
	padding: $l;
}
.pt-l {
	padding-top: $l;
}
.pb-l {
	padding-bottom: $l;
}
.pl-l {
	padding-left: $l;
}
.pr-l {
	padding-right: $l;
}
.ph-l {
	padding-left: $l;
	padding-right: $l;
}
.pv-l {
	padding-top: $l;
	padding-bottom: $l;
}

.padding-l-tablet {
	@include breakpoint (medium down) {
		padding: $l;
	}
}
.pt-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
	}
}
.pb-l-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $l;
	}
}
.pl-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
	}
}
.pr-l-tablet {
	@include breakpoint (medium down) {
		padding-right: $l;
	}
}
.ph-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
		padding-right: $l;
	}
}
.pv-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}

.padding-l-mobile {
	@include breakpoint (small down) {
		padding: $l;
	}
}
.pt-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
	}
}
.pb-l-mobile {
	@include breakpoint (small down) {
		padding-bottom: $l;
	}
}
.pl-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
	}
}
.pr-l-mobile {
	@include breakpoint (small down) {
		padding-right: $l;
	}
}
.ph-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
		padding-right: $l;
	}
}
.pv-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}

.padding-xl {
	padding: $xl;
}
.pt-xl {
	padding-top: $xl;
}
.pb-xl {
	padding-bottom: $xl;
}
.pl-xl {
	padding-left: $xl;
}
.pr-xl {
	padding-right: $xl;
}
.ph-xl {
	padding-left: $xl;
	padding-right: $xl;
}
.pv-xl {
	padding-top: $xl;
	padding-bottom: $xl;
}

.padding-xl-tablet {
	@include breakpoint (medium down) {
		padding: $xl;
	}
}
.pt-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
	}
}
.pb-xl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xl;
	}
}
.pl-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
	}
}
.pr-xl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xl;
	}
}
.ph-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
.pv-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}

.padding-xl-mobile {
	@include breakpoint (small down) {
		padding: $xl;
	}
}
.pt-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
	}
}
.pb-xl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xl;
	}
}
.pl-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
	}
}
.pr-xl-mobile {
	@include breakpoint (small down) {
		padding-right: $xl;
	}
}
.ph-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
.pv-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}

.padding-xxl {
	padding: $xxl;
}
.pt-xxl {
	padding-top: $xxl;
}
.pb-xxl {
	padding-bottom: $xxl;
}
.pl-xxl {
	padding-left: $xxl;
}
.pr-xxl {
	padding-right: $xxl;
}
.ph-xxl {
	padding-left: $xxl;
	padding-right: $xxl;
}
.pv-xxl {
	padding-top: $xxl;
	padding-bottom: $xxl;
}

.padding-xxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxl;
	}
}
.pt-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
	}
}
.pb-xxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxl;
	}
}
.pl-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
	}
}
.pr-xxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxl;
	}
}
.ph-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
.pv-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}

.padding-xxl-mobile {
	@include breakpoint (small down) {
		padding: $xxl;
	}
}
.pt-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
	}
}
.pb-xxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxl;
	}
}
.pl-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
	}
}
.pr-xxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxl;
	}
}
.ph-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
.pv-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}

.padding-xxxl {
	padding: $xxxl;
}
.pt-xxxl {
	padding-top: $xxxl;
}
.pb-xxxl {
	padding-bottom: $xxxl;
}
.pl-xxxl {
	padding-left: $xxxl;
}
.pr-xxxl {
	padding-right: $xxxl;
}
.ph-xxxl {
	padding-left: $xxxl;
	padding-right: $xxxl;
}
.pv-xxxl {
	padding-top: $xxxl;
	padding-bottom: $xxxl;
}

.padding-xxxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxxl;
	}
}
.pt-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
	}
}
.pb-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxl;
	}
}
.pl-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
	}
}
.pr-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxl;
	}
}
.ph-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
.pv-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}

.padding-xxxl-mobile {
	@include breakpoint (small down) {
		padding: $xxxl;
	}
}
.pt-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
	}
}
.pb-xxxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxl;
	}
}
.pl-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
	}
}
.pr-xxxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxl;
	}
}
.ph-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
.pv-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}


// MARGIN MIXINS
@mixin margin-0 {
	margin: 0;
}
@mixin mt-0 {
	margin-top: 0;
}
@mixin mb-0 {
	margin-bottom: 0;
}
@mixin ml-0 {
	margin-left: 0;
}
@mixin mr-0 {
	margin-right: 0;
}
@mixin mh-0 {
	margin-left: 0;
	margin-right: 0;
}
@mixin mv-0 {
	margin-top: 0;
	margin-bottom: 0;
}

@mixin margin-0-tablet {
	@include breakpoint (medium down) {
		margin: 0;
	}
}
@mixin mt-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
	}
}
@mixin mb-0-tablet {
	@include breakpoint (medium down) {
		margin-bottom: 0;
	}
}
@mixin ml-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
	}
}
@mixin mr-0-tablet {
	@include breakpoint (medium down) {
		margin-right: 0;
	}
}
@mixin mh-0-tablet {
	@include breakpoint (medium down) {
		margin-left: 0;
		margin-right: 0;
	}
}
@mixin mv-0-tablet {
	@include breakpoint (medium down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

@mixin margin-0-mobile {
	@include breakpoint (small down) {
		margin: 0;
	}
}
@mixin mt-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
	}
}
@mixin mb-0-mobile {
	@include breakpoint (small down) {
		margin-bottom: 0;
	}
}
@mixin ml-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
	}
}
@mixin mr-0-mobile {
	@include breakpoint (small down) {
		margin-right: 0;
	}
}
@mixin mh-0-mobile {
	@include breakpoint (small down) {
		margin-left: 0;
		margin-right: 0;
	}
}
@mixin mv-0-mobile {
	@include breakpoint (small down) {
		margin-top: 0;
		margin-bottom: 0;
	}
}

@mixin margin-xxs {
	margin: $xxs;
}
@mixin mt-xxs {
	margin-top: $xxs;
}
@mixin mb-xxs {
	margin-bottom: $xxs;
}
@mixin ml-xxs {
	margin-left: $xxs;
}
@mixin mr-xxs {
	margin-right: $xxs;
}
@mixin mh-xxs {
	margin-left: $xxs;
	margin-right: $xxs;
}
@mixin mv-xxs {
	margin-top: $xxs;
	margin-bottom: $xxs;
}

@mixin margin-xxs-tablet {
	@include breakpoint (medium down) {
		margin: $xxs;
	}
}
@mixin mt-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
	}
}
@mixin mb-xxs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxs;
	}
}
@mixin ml-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
	}
}
@mixin mr-xxs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxs;
	}
}
@mixin mh-xxs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
@mixin mv-xxs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}

@mixin margin-xxs-mobile {
	@include breakpoint (small down) {
		margin: $xxs;
	}
}
@mixin mt-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
	}
}
@mixin mb-xxs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxs;
	}
}
@mixin ml-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
	}
}
@mixin mr-xxs-mobile {
	@include breakpoint (small down) {
		margin-right: $xxs;
	}
}
@mixin mh-xxs-mobile {
	@include breakpoint (small down) {
		margin-left: $xxs;
		margin-right: $xxs;
	}
}
@mixin mv-xxs-mobile {
	@include breakpoint (small down) {
		margin-top: $xxs;
		margin-bottom: $xxs;
	}
}

@mixin margin-xs {
	margin: $xs;
}
@mixin mt-xs {
	margin-top: $xs;
}
@mixin mb-xs {
	margin-bottom: $xs;
}
@mixin ml-xs {
	margin-left: $xs;
}
@mixin mr-xs {
	margin-right: $xs;
}
@mixin mh-xs {
	margin-left: $xs;
	margin-right: $xs;
}
@mixin mv-xs {
	margin-top: $xs;
	margin-bottom: $xs;
}

@mixin margin-xs-tablet {
	@include breakpoint (medium down) {
		margin: $xs;
	}
}
@mixin mt-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
	}
}
@mixin mb-xs-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xs;
	}
}
@mixin ml-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
	}
}
@mixin mr-xs-tablet {
	@include breakpoint (medium down) {
		margin-right: $xs;
	}
}
@mixin mh-xs-tablet {
	@include breakpoint (medium down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
@mixin mv-xs-tablet {
	@include breakpoint (medium down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}

@mixin margin-xs-mobile {
	@include breakpoint (small down) {
		margin: $xs;
	}
}
@mixin mt-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
	}
}
@mixin mb-xs-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xs;
	}
}
@mixin ml-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
	}
}
@mixin mr-xs-mobile {
	@include breakpoint (small down) {
		margin-right: $xs;
	}
}
@mixin mh-xs-mobile {
	@include breakpoint (small down) {
		margin-left: $xs;
		margin-right: $xs;
	}
}
@mixin mv-xs-mobile {
	@include breakpoint (small down) {
		margin-top: $xs;
		margin-bottom: $xs;
	}
}

@mixin margin-s {
	margin: $s;
}
@mixin mt-s {
	margin-top: $s;
}
@mixin mb-s {
	margin-bottom: $s;
}
@mixin ml-s {
	margin-left: $s;
}
@mixin mr-s {
	margin-right: $s;
}
@mixin mh-s {
	margin-left: $s;
	margin-right: $s;
}
@mixin mv-s {
	margin-top: $s;
	margin-bottom: $s;
}

@mixin margin-s-tablet {
	@include breakpoint (medium down) {
		margin: $s;
	}
}
@mixin mt-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
	}
}
@mixin mb-s-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $s;
	}
}
@mixin ml-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
	}
}
@mixin mr-s-tablet {
	@include breakpoint (medium down) {
		margin-right: $s;
	}
}
@mixin mh-s-tablet {
	@include breakpoint (medium down) {
		margin-left: $s;
		margin-right: $s;
	}
}
@mixin mv-s-tablet {
	@include breakpoint (medium down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}

@mixin margin-s-mobile {
	@include breakpoint (small down) {
		margin: $s;
	}
}
@mixin mt-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
	}
}
@mixin mb-s-mobile {
	@include breakpoint (small down) {
		margin-bottom: $s;
	}
}
@mixin ml-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
	}
}
@mixin mr-s-mobile {
	@include breakpoint (small down) {
		margin-right: $s;
	}
}
@mixin mh-s-mobile {
	@include breakpoint (small down) {
		margin-left: $s;
		margin-right: $s;
	}
}
@mixin mv-s-mobile {
	@include breakpoint (small down) {
		margin-top: $s;
		margin-bottom: $s;
	}
}

@mixin margin-m {
	margin: $m;
}
@mixin mt-m {
	margin-top: $m;
}
@mixin mb-m {
	margin-bottom: $m;
}
@mixin ml-m {
	margin-left: $m;
}
@mixin mr-m {
	margin-right: $m;
}
@mixin mh-m {
	margin-left: $m;
	margin-right: $m;
}
@mixin mv-m {
	margin-top: $m;
	margin-bottom: $m;
}

@mixin margin-m-tablet {
	@include breakpoint (medium down) {
		margin: $m;
	}
}
@mixin mt-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
	}
}
@mixin mb-m-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $m;
	}
}
@mixin ml-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
	}
}
@mixin mr-m-tablet {
	@include breakpoint (medium down) {
		margin-right: $m;
	}
}
@mixin mh-m-tablet {
	@include breakpoint (medium down) {
		margin-left: $m;
		margin-right: $m;
	}
}
@mixin mv-m-tablet {
	@include breakpoint (medium down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}

@mixin margin-m-mobile {
	@include breakpoint (small down) {
		margin: $m;
	}
}
@mixin mt-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
	}
}
@mixin mb-m-mobile {
	@include breakpoint (small down) {
		margin-bottom: $m;
	}
}
@mixin ml-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
	}
}
@mixin mr-m-mobile {
	@include breakpoint (small down) {
		margin-right: $m;
	}
}
@mixin mh-m-mobile {
	@include breakpoint (small down) {
		margin-left: $m;
		margin-right: $m;
	}
}
@mixin mv-m-mobile {
	@include breakpoint (small down) {
		margin-top: $m;
		margin-bottom: $m;
	}
}

@mixin margin-l {
	margin: $l;
}
@mixin mt-l {
	margin-top: $l;
}
@mixin mb-l {
	margin-bottom: $l;
}
@mixin ml-l {
	margin-left: $l;
}
@mixin mr-l {
	margin-right: $l;
}
@mixin mh-l {
	margin-left: $l;
	margin-right: $l;
}
@mixin mv-l {
	margin-top: $l;
	margin-bottom: $l;
}

@mixin margin-l-tablet {
	@include breakpoint (medium down) {
		margin: $l;
	}
}
@mixin mt-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
	}
}
@mixin mb-l-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $l;
	}
}
@mixin ml-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
	}
}
@mixin mr-l-tablet {
	@include breakpoint (medium down) {
		margin-right: $l;
	}
}
@mixin mh-l-tablet {
	@include breakpoint (medium down) {
		margin-left: $l;
		margin-right: $l;
	}
}
@mixin mv-l-tablet {
	@include breakpoint (medium down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}

@mixin margin-l-mobile {
	@include breakpoint (small down) {
		margin: $l;
	}
}
@mixin mt-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
	}
}
@mixin mb-l-mobile {
	@include breakpoint (small down) {
		margin-bottom: $l;
	}
}
@mixin ml-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
	}
}
@mixin mr-l-mobile {
	@include breakpoint (small down) {
		margin-right: $l;
	}
}
@mixin mh-l-mobile {
	@include breakpoint (small down) {
		margin-left: $l;
		margin-right: $l;
	}
}
@mixin mv-l-mobile {
	@include breakpoint (small down) {
		margin-top: $l;
		margin-bottom: $l;
	}
}

@mixin margin-xl {
	margin: $xl;
}
@mixin mt-xl {
	margin-top: $xl;
}
@mixin mb-xl {
	margin-bottom: $xl;
}
@mixin ml-xl {
	margin-left: $xl;
}
@mixin mr-xl {
	margin-right: $xl;
}
@mixin mh-xl {
	margin-left: $xl;
	margin-right: $xl;
}
@mixin mv-xl {
	margin-top: $xl;
	margin-bottom: $xl;
}

@mixin margin-xl-tablet {
	@include breakpoint (medium down) {
		margin: $xl;
	}
}
@mixin mt-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
	}
}
@mixin mb-xl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xl;
	}
}
@mixin ml-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
	}
}
@mixin mr-xl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xl;
	}
}
@mixin mh-xl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
@mixin mv-xl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}

@mixin margin-xl-mobile {
	@include breakpoint (small down) {
		margin: $xl;
	}
}
@mixin mt-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
	}
}
@mixin mb-xl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xl;
	}
}
@mixin ml-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
	}
}
@mixin mr-xl-mobile {
	@include breakpoint (small down) {
		margin-right: $xl;
	}
}
@mixin mh-xl-mobile {
	@include breakpoint (small down) {
		margin-left: $xl;
		margin-right: $xl;
	}
}
@mixin mv-xl-mobile {
	@include breakpoint (small down) {
		margin-top: $xl;
		margin-bottom: $xl;
	}
}

@mixin margin-xxl {
	margin: $xxl;
}
@mixin mt-xxl {
	margin-top: $xxl;
}
@mixin mb-xxl {
	margin-bottom: $xxl;
}
@mixin ml-xxl {
	margin-left: $xxl;
}
@mixin mr-xxl {
	margin-right: $xxl;
}
@mixin mh-xxl {
	margin-left: $xxl;
	margin-right: $xxl;
}
@mixin mv-xxl {
	margin-top: $xxl;
	margin-bottom: $xxl;
}

@mixin margin-xxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxl;
	}
}
@mixin mt-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
	}
}
@mixin mb-xxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxl;
	}
}
@mixin ml-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
	}
}
@mixin mr-xxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxl;
	}
}
@mixin mh-xxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
@mixin mv-xxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}

@mixin margin-xxl-mobile {
	@include breakpoint (small down) {
		margin: $xxl;
	}
}
@mixin mt-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
	}
}
@mixin mb-xxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxl;
	}
}
@mixin ml-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
	}
}
@mixin mr-xxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxl;
	}
}
@mixin mh-xxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxl;
		margin-right: $xxl;
	}
}
@mixin mv-xxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxl;
		margin-bottom: $xxl;
	}
}

@mixin margin-xxxl {
	margin: $xxxl;
}
@mixin mt-xxxl {
	margin-top: $xxxl;
}
@mixin mb-xxxl {
	margin-bottom: $xxxl;
}
@mixin ml-xxxl {
	margin-left: $xxxl;
}
@mixin mr-xxxl {
	margin-right: $xxxl;
}
@mixin mh-xxxl {
	margin-left: $xxxl;
	margin-right: $xxxl;
}
@mixin mv-xxxl {
	margin-top: $xxxl;
	margin-bottom: $xxxl;
}

@mixin margin-xxxl-tablet {
	@include breakpoint (medium down) {
		margin: $xxxl;
	}
}
@mixin mt-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
	}
}
@mixin mb-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-bottom: $xxxl;
	}
}
@mixin ml-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
	}
}
@mixin mr-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-right: $xxxl;
	}
}
@mixin mh-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
@mixin mv-xxxl-tablet {
	@include breakpoint (medium down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}

@mixin margin-xxxl-mobile {
	@include breakpoint (small down) {
		margin: $xxxl;
	}
}
@mixin mt-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
	}
}
@mixin mb-xxxl-mobile {
	@include breakpoint (small down) {
		margin-bottom: $xxxl;
	}
}
@mixin ml-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
	}
}
@mixin mr-xxxl-mobile {
	@include breakpoint (small down) {
		margin-right: $xxxl;
	}
}
@mixin mh-xxxl-mobile {
	@include breakpoint (small down) {
		margin-left: $xxxl;
		margin-right: $xxxl;
	}
}
@mixin mv-xxxl-mobile {
	@include breakpoint (small down) {
		margin-top: $xxxl;
		margin-bottom: $xxxl;
	}
}


// PADDING MIXINS
@mixin padding-0 {
	padding: 0;
}
@mixin pt-0 {
	padding-top: 0;
}
@mixin pb-0 {
	padding-bottom: 0;
}
@mixin pl-0 {
	padding-left: 0;
}
@mixin pr-0 {
	padding-right: 0;
}
@mixin ph-0 {
	padding-left: 0;
	padding-right: 0;
}
@mixin pv-0 {
	padding-top: 0;
	padding-bottom: 0;
}

@mixin padding-0-tablet {
	@include breakpoint (medium down) {
		padding: 0;
	}
}
@mixin pt-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
	}
}
@mixin pb-0-tablet {
	@include breakpoint (medium down) {
		padding-bottom: 0;
	}
}
@mixin pl-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
	}
}
@mixin pr-0-tablet {
	@include breakpoint (medium down) {
		padding-right: 0;
	}
}
@mixin ph-0-tablet {
	@include breakpoint (medium down) {
		padding-left: 0;
		padding-right: 0;
	}
}
@mixin pv-0-tablet {
	@include breakpoint (medium down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

@mixin padding-0-mobile {
	@include breakpoint (small down) {
		padding: 0;
	}
}
@mixin pt-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
	}
}
@mixin pb-0-mobile {
	@include breakpoint (small down) {
		padding-bottom: 0;
	}
}
@mixin pl-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
	}
}
@mixin pr-0-mobile {
	@include breakpoint (small down) {
		padding-right: 0;
	}
}
@mixin ph-0-mobile {
	@include breakpoint (small down) {
		padding-left: 0;
		padding-right: 0;
	}
}
@mixin pv-0-mobile {
	@include breakpoint (small down) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

@mixin padding-xxs {
	padding: $xxs;
}
@mixin pt-xxs {
	padding-top: $xxs;
}
@mixin pb-xxs {
	padding-bottom: $xxs;
}
@mixin pl-xxs {
	padding-left: $xxs;
}
@mixin pr-xxs {
	padding-right: $xxs;
}
@mixin ph-xxs {
	padding-left: $xxs;
	padding-right: $xxs;
}
@mixin pv-xxs {
	padding-top: $xxs;
	padding-bottom: $xxs;
}

@mixin padding-xxs-tablet {
	@include breakpoint (medium down) {
		padding: $xxs;
	}
}
@mixin pt-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
	}
}
@mixin pb-xxs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxs;
	}
}
@mixin pl-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
	}
}
@mixin pr-xxs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxs;
	}
}
@mixin ph-xxs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
@mixin pv-xxs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}

@mixin padding-xxs-mobile {
	@include breakpoint (small down) {
		padding: $xxs;
	}
}
@mixin pt-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
	}
}
@mixin pb-xxs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxs;
	}
}
@mixin pl-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
	}
}
@mixin pr-xxs-mobile {
	@include breakpoint (small down) {
		padding-right: $xxs;
	}
}
@mixin ph-xxs-mobile {
	@include breakpoint (small down) {
		padding-left: $xxs;
		padding-right: $xxs;
	}
}
@mixin pv-xxs-mobile {
	@include breakpoint (small down) {
		padding-top: $xxs;
		padding-bottom: $xxs;
	}
}

@mixin padding-xs {
	padding: $xs;
}
@mixin pt-xs {
	padding-top: $xs;
}
@mixin pb-xs {
	padding-bottom: $xs;
}
@mixin pl-xs {
	padding-left: $xs;
}
@mixin pr-xs {
	padding-right: $xs;
}
@mixin ph-xs {
	padding-left: $xs;
	padding-right: $xs;
}
@mixin pv-xs {
	padding-top: $xs;
	padding-bottom: $xs;
}

@mixin padding-xs-tablet {
	@include breakpoint (medium down) {
		padding: $xs;
	}
}
@mixin pt-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
	}
}
@mixin pb-xs-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xs;
	}
}
@mixin pl-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
	}
}
@mixin pr-xs-tablet {
	@include breakpoint (medium down) {
		padding-right: $xs;
	}
}
@mixin ph-xs-tablet {
	@include breakpoint (medium down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
@mixin pv-xs-tablet {
	@include breakpoint (medium down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}

@mixin padding-xs-mobile {
	@include breakpoint (small down) {
		padding: $xs;
	}
}
@mixin pt-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
	}
}
@mixin pb-xs-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xs;
	}
}
@mixin pl-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
	}
}
@mixin pr-xs-mobile {
	@include breakpoint (small down) {
		padding-right: $xs;
	}
}
@mixin ph-xs-mobile {
	@include breakpoint (small down) {
		padding-left: $xs;
		padding-right: $xs;
	}
}
@mixin pv-xs-mobile {
	@include breakpoint (small down) {
		padding-top: $xs;
		padding-bottom: $xs;
	}
}

@mixin padding-s {
	padding: $s;
}
@mixin pt-s {
	padding-top: $s;
}
@mixin pb-s {
	padding-bottom: $s;
}
@mixin pl-s {
	padding-left: $s;
}
@mixin pr-s {
	padding-right: $s;
}
@mixin ph-s {
	padding-left: $s;
	padding-right: $s;
}
@mixin pv-s {
	padding-top: $s;
	padding-bottom: $s;
}

@mixin padding-s-tablet {
	@include breakpoint (medium down) {
		padding: $s;
	}
}
@mixin pt-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
	}
}
@mixin pb-s-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $s;
	}
}
@mixin pl-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
	}
}
@mixin pr-s-tablet {
	@include breakpoint (medium down) {
		padding-right: $s;
	}
}
@mixin ph-s-tablet {
	@include breakpoint (medium down) {
		padding-left: $s;
		padding-right: $s;
	}
}
@mixin pv-s-tablet {
	@include breakpoint (medium down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}

@mixin padding-s-mobile {
	@include breakpoint (small down) {
		padding: $s;
	}
}
@mixin pt-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
	}
}
@mixin pb-s-mobile {
	@include breakpoint (small down) {
		padding-bottom: $s;
	}
}
@mixin pl-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
	}
}
@mixin pr-s-mobile {
	@include breakpoint (small down) {
		padding-right: $s;
	}
}
@mixin ph-s-mobile {
	@include breakpoint (small down) {
		padding-left: $s;
		padding-right: $s;
	}
}
@mixin pv-s-mobile {
	@include breakpoint (small down) {
		padding-top: $s;
		padding-bottom: $s;
	}
}

@mixin padding-m {
	padding: $m;
}
@mixin pt-m {
	padding-top: $m;
}
@mixin pb-m {
	padding-bottom: $m;
}
@mixin pl-m {
	padding-left: $m;
}
@mixin pr-m {
	padding-right: $m;
}
@mixin ph-m {
	padding-left: $m;
	padding-right: $m;
}
@mixin pv-m {
	padding-top: $m;
	padding-bottom: $m;
}

@mixin padding-m-tablet {
	@include breakpoint (medium down) {
		padding: $m;
	}
}
@mixin pt-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
	}
}
@mixin pb-m-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $m;
	}
}
@mixin pl-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
	}
}
@mixin pr-m-tablet {
	@include breakpoint (medium down) {
		padding-right: $m;
	}
}
@mixin ph-m-tablet {
	@include breakpoint (medium down) {
		padding-left: $m;
		padding-right: $m;
	}
}
@mixin pv-m-tablet {
	@include breakpoint (medium down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}

@mixin padding-m-mobile {
	@include breakpoint (small down) {
		padding: $m;
	}
}
@mixin pt-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
	}
}
@mixin pb-m-mobile {
	@include breakpoint (small down) {
		padding-bottom: $m;
	}
}
@mixin pl-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
	}
}
@mixin pr-m-mobile {
	@include breakpoint (small down) {
		padding-right: $m;
	}
}
@mixin ph-m-mobile {
	@include breakpoint (small down) {
		padding-left: $m;
		padding-right: $m;
	}
}
@mixin pv-m-mobile {
	@include breakpoint (small down) {
		padding-top: $m;
		padding-bottom: $m;
	}
}

@mixin padding-l {
	padding: $l;
}
@mixin pt-l {
	padding-top: $l;
}
@mixin pb-l {
	padding-bottom: $l;
}
@mixin pl-l {
	padding-left: $l;
}
@mixin pr-l {
	padding-right: $l;
}
@mixin ph-l {
	padding-left: $l;
	padding-right: $l;
}
@mixin pv-l {
	padding-top: $l;
	padding-bottom: $l;
}

@mixin padding-l-tablet {
	@include breakpoint (medium down) {
		padding: $l;
	}
}
@mixin pt-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
	}
}
@mixin pb-l-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $l;
	}
}
@mixin pl-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
	}
}
@mixin pr-l-tablet {
	@include breakpoint (medium down) {
		padding-right: $l;
	}
}
@mixin ph-l-tablet {
	@include breakpoint (medium down) {
		padding-left: $l;
		padding-right: $l;
	}
}
@mixin pv-l-tablet {
	@include breakpoint (medium down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}

@mixin padding-l-mobile {
	@include breakpoint (small down) {
		padding: $l;
	}
}
@mixin pt-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
	}
}
@mixin pb-l-mobile {
	@include breakpoint (small down) {
		padding-bottom: $l;
	}
}
@mixin pl-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
	}
}
@mixin pr-l-mobile {
	@include breakpoint (small down) {
		padding-right: $l;
	}
}
@mixin ph-l-mobile {
	@include breakpoint (small down) {
		padding-left: $l;
		padding-right: $l;
	}
}
@mixin pv-l-mobile {
	@include breakpoint (small down) {
		padding-top: $l;
		padding-bottom: $l;
	}
}

@mixin padding-xl {
	padding: $xl;
}
@mixin pt-xl {
	padding-top: $xl;
}
@mixin pb-xl {
	padding-bottom: $xl;
}
@mixin pl-xl {
	padding-left: $xl;
}
@mixin pr-xl {
	padding-right: $xl;
}
@mixin ph-xl {
	padding-left: $xl;
	padding-right: $xl;
}
@mixin pv-xl {
	padding-top: $xl;
	padding-bottom: $xl;
}

@mixin padding-xl-tablet {
	@include breakpoint (medium down) {
		padding: $xl;
	}
}
@mixin pt-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
	}
}
@mixin pb-xl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xl;
	}
}
@mixin pl-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
	}
}
@mixin pr-xl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xl;
	}
}
@mixin ph-xl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
@mixin pv-xl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}

@mixin padding-xl-mobile {
	@include breakpoint (small down) {
		padding: $xl;
	}
}
@mixin pt-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
	}
}
@mixin pb-xl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xl;
	}
}
@mixin pl-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
	}
}
@mixin pr-xl-mobile {
	@include breakpoint (small down) {
		padding-right: $xl;
	}
}
@mixin ph-xl-mobile {
	@include breakpoint (small down) {
		padding-left: $xl;
		padding-right: $xl;
	}
}
@mixin pv-xl-mobile {
	@include breakpoint (small down) {
		padding-top: $xl;
		padding-bottom: $xl;
	}
}

@mixin padding-xxl {
	padding: $xxl;
}
@mixin pt-xxl {
	padding-top: $xxl;
}
@mixin pb-xxl {
	padding-bottom: $xxl;
}
@mixin pl-xxl {
	padding-left: $xxl;
}
@mixin pr-xxl {
	padding-right: $xxl;
}
@mixin ph-xxl {
	padding-left: $xxl;
	padding-right: $xxl;
}
@mixin pv-xxl {
	padding-top: $xxl;
	padding-bottom: $xxl;
}

@mixin padding-xxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxl;
	}
}
@mixin pt-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
	}
}
@mixin pb-xxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxl;
	}
}
@mixin pl-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
	}
}
@mixin pr-xxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxl;
	}
}
@mixin ph-xxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
@mixin pv-xxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}

@mixin padding-xxl-mobile {
	@include breakpoint (small down) {
		padding: $xxl;
	}
}
@mixin pt-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
	}
}
@mixin pb-xxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxl;
	}
}
@mixin pl-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
	}
}
@mixin pr-xxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxl;
	}
}
@mixin ph-xxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxl;
		padding-right: $xxl;
	}
}
@mixin pv-xxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxl;
		padding-bottom: $xxl;
	}
}

@mixin padding-xxxl {
	padding: $xxxl;
}
@mixin pt-xxxl {
	padding-top: $xxxl;
}
@mixin pb-xxxl {
	padding-bottom: $xxxl;
}
@mixin pl-xxxl {
	padding-left: $xxxl;
}
@mixin pr-xxxl {
	padding-right: $xxxl;
}
@mixin ph-xxxl {
	padding-left: $xxxl;
	padding-right: $xxxl;
}
@mixin pv-xxxl {
	padding-top: $xxxl;
	padding-bottom: $xxxl;
}

@mixin padding-xxxl-tablet {
	@include breakpoint (medium down) {
		padding: $xxxl;
	}
}
@mixin pt-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
	}
}
@mixin pb-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-bottom: $xxxl;
	}
}
@mixin pl-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
	}
}
@mixin pr-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-right: $xxxl;
	}
}
@mixin ph-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
@mixin pv-xxxl-tablet {
	@include breakpoint (medium down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}

@mixin padding-xxxl-mobile {
	@include breakpoint (small down) {
		padding: $xxxl;
	}
}
@mixin pt-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
	}
}
@mixin pb-xxxl-mobile {
	@include breakpoint (small down) {
		padding-bottom: $xxxl;
	}
}
@mixin pl-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
	}
}
@mixin pr-xxxl-mobile {
	@include breakpoint (small down) {
		padding-right: $xxxl;
	}
}
@mixin ph-xxxl-mobile {
	@include breakpoint (small down) {
		padding-left: $xxxl;
		padding-right: $xxxl;
	}
}
@mixin pv-xxxl-mobile {
	@include breakpoint (small down) {
		padding-top: $xxxl;
		padding-bottom: $xxxl;
	}
}

