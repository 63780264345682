// top header

.header-fixed {
  position: fixed;
  background: #fefefe;
  z-index: 7;
  width: 100%;
  top:0;
}

.body-container {
  margin-top: 141.1px;
  @include breakpoint(small down) {
    margin-top: 64.1px;
  }
  @include breakpoint(medium down) {
    margin-top: 64.1px;
  }
}

.header-utilities {
  background-color: $black;
}

.header-cart {
  padding: 0.5rem;
}

.header-utilities__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;

    a, span,
    input[type="submit"] {
      @include nexa-bold;
      text-transform: uppercase;
      font-size: 11px;
      color: white;
      position: relative;
      top: -2px;
      background: none;
      border: none;
      cursor: pointer;
    }
}

.header-utilities__search-form {
  position: relative;

    img {
      width: 12px;
      position: absolute;
      left: 0;
      top: 13px;
    }
}

.header-utilities__search-input {
  @include nexa-bold;
  background: transparent;
  border: none;
  box-shadow: none;
  margin: 0;
  font-size: 11px;
  text-transform: uppercase;
  color: white;
  padding-left: 16px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: white;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: white;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: white;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: white;
    }

    &:focus {
      background: transparent;
      border: none;
      box-shadow: none;
    }
}

.header-utilities__side {
  width: 400px;

    &.-right {
      text-align: right;
    }
}

.header-utilities__space {
  margin: 0 15px;
  display: inline-block;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.-icon {
    position: relative;
    padding-left: 15px;

      &::after {
        content: '';
        background-image: url('/skins/current-skin/images/icons/phone-blue.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 11px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 2px;
      }
  }

  &.header-dropshipping {
    display: inline-block;
    background-color: $light-blue;
    padding: 8px;

    img {
      width: 20px;
      margin-right: 8px;
      position: relative;
      top: -1px;
    }
  }
}

.header-utilities__cart {
  position: relative;

    img {
      width: 20px;
      position: relative;
      top: -2px;
    }
}

.margin-right-0 {
  margin-right: 0;
}

.header-utilities__wishlist {
  position: relative;

    img {
      width: 20px;
      position: relative;
      top: -3px;
    }
}

.header-utilities__separator {
  padding: 0 4px;
}

.header-utilities__language-dropdown {
  position: relative;
  cursor: pointer;

    span {
      position: relative;
      padding-right: 12px;

        &::after {
          content: '';
          background-image: url('/skins/current-skin/images/icons/select-arrow.svg');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 8px;
          height: 6px;
          position: absolute;
          right: 0;
          top: 4px;
        }
    }

    ul {
      display: none;
      position: absolute;
      list-style: none;
      background-color: $black;
      width: 78px;
      text-align: center;
      top: 20px;
      left: -25px;
      padding: 20px 0 5px;
    }

    &:hover {

      ul {
        display: block;
      }
    }
}

.header-utilities__profile-dropdown {
  position: relative;

    img {
      width: 20px;
      position: relative;
      top: -3px;
    }

    li {
      cursor: pointer;

      span {
        cursor: pointer !important;
      }

    }

    span {
      position: relative;
      padding-right: 5px;
      cursor: default;

    }

    ul {
      display: none;
      position: absolute;
      list-style: none;
      background-color: $black;
      width: 132px;
      text-align: initial;
      top: 20px;
      left: -25px;
      padding: 20px 0 5px;
    }

    li > a {
      padding-left: 24px;
    }

    li > form > input {
      padding-left: 24px;
    }

    &:hover {

      ul {
        display: block;
      }
    }
}

.header-span {
  padding-right: 5px;
  top: 0px !important;
}

.cart-dot {
  width: 6px;
  height: 6px;
  background-color: $light-blue !important;
  position: absolute !important;
  display: block;
  z-index: 1;
  top: -4px !important;
  right: -6px;
  border-radius: 3px;
}



// main header
.header-main {
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    padding: 10px 0 9px;
  }
}

.header-main__flex {
  display: flex;
  justify-content: center;
}

.header-main__side {
  align-self: center;
  width: 100px;

    &.-right {
      text-align: right;

        img {
          width: 80px;
          @include breakpoint(small down) {
            width: 70px;
          }
        }
    }

    img {
      width: 60px;
      @include breakpoint(small down) {
        width: 44px;
      }
    }
}

.header-main__center {
  text-align: center;
}

.header-main__logo {
  width: 280px;

  @media screen and (max-width: 39.9375em) {
    padding: 12px 0 11px;
  }
  @media screen and (min-width: 40em) {
    padding: 30px 0 20px;
  }

}

.top-banner {
  font-size: 14px;
  padding: 6px 32px;
  p{
    color: white;
    @include nexa-bold;
    text-transform: uppercase;
  }
}