.login-subscribe-container {
  padding: 95px 0 70px;
}

.login-title {
  font-size: 40px;
  font-weight: 200;
  margin: 0 0 50px;
  padding: 0 0 5px;
  text-align: center;
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    font-size: 30px;
  }
    &.weight-100{
    font-weight: 100;
  }
    &.-small {
      font-size: 25px;
      color: $black;
    }
}

.login-link {
  @include nexa-bold;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.1em;
  padding: 10px 0px;
  border: 1px solid $black; 
}

.remember-me {
  margin-bottom: 16px;
}