// button
.btn {
  @include nexa-bold;
  @include transition-basic;
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 0.1em;
  display: inline-block;
  padding: 12px 0 10px;
  border: none;
  cursor: pointer;
  text-align: center;

    &.btn-blue {
      background-color: $light-blue;
      border: 1px solid $light-blue;
      color: white;

        &:hover {
          background-color: $light-blue-hover;
        }
    }

    &.btn-border{
      border: 1px solid $black;
      background-color: white;
    }

    &.btn-black {
      background-color: $black;
      border: 1px solid $black;
      color: white;

      &:hover {
        background-color: $black-hover;
      }
    }

    &.btn-white {
      background-color: white;
      border: 1px solid $black;
    }

    &.btn-padding {
      padding-left: 45px;
      padding-right: 45px;

      @include breakpoint(small down) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &.in-two-columns {
      @include breakpoint(small down) {
        margin-bottom: 5px;
      }
    }

    &.btn-small {
      font-size: 11px;
      padding: 10px 0 9px;
      width: 130px;
      text-align: center;
    }

    &.btn-profile {
      border: 1px solid $black;
      padding: 5px 12px 3px;
      margin-right: -1px;
      font-size: 12px;

        &:hover {
          background-color: $black;
          color: white;
        }
    }

    &:active {
      position: relative;
      top: 1px;
    }

    &:disabled {
      opacity: .4;
    }
}

.error {
  border: 1px solid #DD1331;
}



// input
.label-input {

    label {
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.1em;
    }

    input,
    textarea,
    select {
      @include nexa-bold;
      box-shadow: none;
      border-color: #e4e6eb;
      font-size: 14px;
      letter-spacing: 0.05em;
      padding-left: 12px;

        &::-webkit-input-placeholder {
          @include nexa-light;
        }
        &::-moz-placeholder {
          @include nexa-light;
        }
        &:-ms-input-placeholder {
          @include nexa-light;
        }
        &:-moz-placeholder {
          @include nexa-light;
        }
    }
}



// checkbox
.styled-checkbox {
  position: absolute;
  opacity: 0;

  & + label,
  & + label a {
    @include nexa-bold;
    position: relative;
    padding: 0;
    margin: 5px 0;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.1em;

      &.is-invalid-label,
      &.is-invalid-label a {
        color: $red;
      }
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: none;
    border: 1px solid $black;
    position: relative;
    top: -1px;
  }

  &:hover + label:before {
    background: $black;
  }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  &:checked + label:before {
    background: $black;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 8px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
    transform: rotate(45deg);
  }
}