.product-item {
  position: relative;
  text-align: center;

    &:hover {
      .product-item__img img+img {
        opacity: 1;
      }
    }
}

.product-item__img {
  min-height: 167px;
  position: relative;
  border: 1px solid $line-color;
  margin: 0 0 10px;

    img+img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      z-index: 1;
    }
}

.product-item__brand {
  @include garamond-regular-italic;
  font-size: 24px;
  margin-bottom: 3px;
  @include breakpoint(small down) {
    font-size: 18px;
  }
}

.product-item__descr {
  @include nexa-bold;
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 0.05em;
}

.product-item__price {
  @include nexa-bold;
  display: block;
  font-size: 22px;
  line-height: normal;
}

.product-item__retail-price {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.05em;
  color: $text-gray;
  margin-top: -1px;
  display: block;
}

.product-item__price-block {
  margin: 5px 0 15px;
}

.product-item__link {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
}

.product-item__table {
  @include breakpoint(small down) {
    margin: 10px 0 60px;
  }
}

.product-item__description {
  border-top: 1px solid $line-color;
  padding-top: 15px;
}