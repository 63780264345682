@font-face {
  font-family: Nexa;
  src: url(/skins/bonbon/fonts/nexa/nexa_light-webfont.woff2) format('woff2'),
  url(/skins/bonbon/fonts/nexa/nexa_light-webfont.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: Nexa;
  src: url(/skins/bonbon/fonts/nexa/nexa_bold-webfont.woff2) format('woff2'),
  url(/skins/bonbon/fonts/nexa/nexa_bold-webfont.woff) format('woff');
  font-weight: 700;
}