.content-404 {
  margin: 9vw 0 10vw;
  @include breakpoint(small down) {
    margin: 55px 0 70px;
  }

    h1 {
      @include nexa-bold;
      font-size: 60px;
      line-height: 1;
      letter-spacing: 0.05em;
    }

    h2 {
      font-size: 30px;
      letter-spacing: 0.01em;
    }

    p {
      margin: 3px 0 25px;
    }
}

#row-call {
  color: white;
  background: #2b2b2b;
  margin: 40px 0px;

  @media screen and (max-width: 39.9375em) {
    text-align: center;
    padding-bottom: 20px;
  }  
  @media screen and (min-width: 40em) {
    display:flex;
    align-items: center;
    justify-content: center;
  }

}

#text-row-call {
  font-size:14px;
  font-weight:600;
  text-align:center;
  color:white;

  @media screen and (max-width: 39.9375em) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}

#btn-row-call {
  @media screen and (min-width: 40em) {
    margin-left: 40px;
  }
  border: none;
}

.cms-wrapper {
  margin-bottom: 100px;
}

.cms-page{
  margin:20px 0px 20px 0px;
  
  h1{
    @include nexa-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 20px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h2{
    @include nexa-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 16px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h3,h4{
    @include nexa-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 14px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  
  p{
    @include nexa-light;
    letter-spacing: .05em;
    font-style: normal;
    font-size: 14px;
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }

    &.lateral-padding {
      padding: 0 1.625rem;
    }

  }

  b{
    @include nexa-bold;
  }

  ul{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  ol{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  .text-center {
    max-width: 1000px;
  }
}

.cms-cover-title-white{
  color: white;
  font-size:50px;
  margin-top:50px;
  @include breakpoint(small down) {
   font-size:40px;
  }

}
.cms-cover-title-black{
  color: $black;
  font-size:50px;
  margin-top:30px;
  @include breakpoint(small down) {
    font-size:40px;
   }
&.line{
border-bottom: 1px solid $line-color;
}

}

.header-title {
  border-bottom: 1px solid $line-color;
}

.size-table.title > span {
  font-size: 28px;
  font-family: Cormorant Garamond,Georgia,serif;
  padding: 0 20px;
  margin: 80px 0 40px 0;
  letter-spacing: .02em;
}

.contact-title {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .15em;
  margin: 10px 0 10px 0;
}

.contact-subtitle {
  margin: 12px 0 16px;
  font-size: 19px;
}

.contact-text {
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: .15em;
}


.holiday-alert{
  color: $red;
  @include nexa-bold;
    font-size:12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
}
/* popup */
.title-popup{
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-top:10px;
}

.close-popup{
  width: 16px;
  float: right;
  margin-top: 7px;
}

.cms-lateral-padding{
  text-align: center;
  padding: 0 1rem;
  @include breakpoint(small down) {
    padding: 0 1.625rem 1rem 1.625rem;
      &.text, &.icons{
        padding: 0 1.625rem;
      }
    }
}


.cms-lateral-margin{
  margin: 20px 0;
  
  h1{
    @include nexa-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 20px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h2{
    @include nexa-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 16px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  h3,h4{
    @include nexa-bold;
    font-style: normal;
    letter-spacing: .05em;
    font-size: 14px; 
    padding-bottom: 25px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  
  p{
    @include nexa-light;
    letter-spacing: .05em;
    font-style: normal;
    font-size: 14px;
    padding-bottom: 25px;

  }

  b{
    @include nexa-bold;
  }

  ul{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }
  ol{
    margin-left:20px;
    @include breakpoint(small down) {
      padding: 0 15px 25px 15px;
    }
    @include breakpoint(medium down) {
      padding: 0 15px 25px 15px;
    }
  }

  .text-center {
    max-width: 1000px;
  }
}

.home-brands-label {
  position: absolute;
  bottom: 12px;
  background-color: #00CFFE;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  p {
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 4px;
    @include breakpoint(medium only) {
      font-size: 17px;
    }
  }

  &.new-arrivals {
    bottom: 0;
    background-color: $light-blue;
    height: 50px;

    p {
      font-size: 25px;
    }
  }
}

.new-arrivals-text {
  .main-title {
    text-align: center;
    margin-top: 25px;
    font-size: 50px;
    line-height: 56px;
  }
  .subtitle {
    text-align: center;
    margin-top: 10px;
  }
}

.new-arrivals-link {
  display: block;
  position: relative;
}

.new-arrivals-container {
  @include breakpoint(small down) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.dropshipping-page-title {
  text-align: center;
  padding: 48px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  * {
    font-size: 50px;
  }
}

.dropshipping-text-block {
  margin-top: 80px;
  padding-bottom: 20px;
  font-size: 24px;
  line-height: 24px;

  b, strong {
    font-weight: bold;
  }

  * {
    font-size: 20px;
    line-height: 24px;
  }

  @include breakpoint (medium down) {
    font-size: 20px;

    * {
      font-size: 20px;
    }
  }
}

.dropshipping-box-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 30px;

  .title-box {
    text-align: center;
    padding: 16px 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    * {
      font-size: 30px;
    }
  }
}

.dropshipping-how-it-works {
  margin: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  @include breakpoint (medium down) {
    margin: 24px 0;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .number {
      color: $light-blue;
      font-size: 24px;
      font-weight: bold;
      margin-right: 16px;
    }
  
    img {
      width: 40px;
    }

    @include breakpoint (medium down) {
      .number {
        font-size: 40px;
      }
  
      img {
        width: 48px;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: bold;
  }

  .description {
    font-size: 16px;
    margin-top: 16px;
  }
}

.dropshipping-advantages {
  justify-content: center;
  align-items: flex-start;
  margin-top: 40px;

  ol {
    counter-reset: dropshipping-advantages-counter;
  }

  ol li {
    counter-increment: dropshipping-advantages-counter;
    position: relative;
    left: 17%;
    display: flex;
    align-items: baseline;

    @include breakpoint (medium down) {
      left: 32px;
    }

    > * {
      font-size: 14px;
      margin: 16px 0;

      @include breakpoint (medium down) {
        font-size: 14px;
        max-width: calc(100% - 56px);
      }
    }
  }

  ol li::before {
    content: counter(dropshipping-advantages-counter);
    color: $black;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    left: -16px;
    display: inline-block;
    width: 1em;
    text-align: center;

    @include breakpoint (medium down) {
      left: -24px;
    }
  }
}

.dropshipping-box {
  margin-top: 40px;
  text-align: center;
  padding: 24px;
  background-color: #fff;
  border: 1px solid $black;
  box-shadow: 8px 8px $text-gray;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint (medium down) {
    margin-top: 32px;
  }

  &.single {
    padding: 24px 48px;

    @include breakpoint (medium down) {
      padding: 24px;
    }

    .btn {
      margin-top: 24px;
      width: calc(100% / 3);

      @include breakpoint (medium down) {
        width: 100%;
      }

      &.full {
        width: 100%;
        margin-right: 0;
      }
    }
  }

  img {
    width: 100px;

    &.triple {
      width: auto;
      height: 112px;
    }

    &.quadruple{
      width:auto;
      height: 70px;
      margin: 21px 0px;
    }
  }

  .box-title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 16px;
  }

  .box-title-large {
    font-size: 26px;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;

    .number {
      font-size: 20px;
      font-weight: bold;
      margin-right: 32px;
    }

    @include breakpoint (medium down) {
      font-size: 24px;

      .number {
        margin-right: 16px;
      }
    }
  }

  .box-title-xlarge {
    font-size: 30px;
    font-weight: bold;
    margin-top: 16px;
  }

  .promotion-box {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    font-size: 16px;
    margin-top: 8px;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;

    p {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .box-description {
    font-size: 14px;
    margin-top: 40px;
    margin-bottom: 16px;
    text-align: left;

    @include breakpoint (medium down) {
      margin-top: 16px;
    }

    .subscription-required {
      font-weight: bold;
    }

    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .terms-and-conditions label {
    font-size: 14px;
    font-weight: normal;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
    text-align: left;
    white-space: nowrap;

    * {
      font-size: 16px;
    }

    a {
      font-size: 14px;
      font-weight: bold;
      text-decoration: underline;
    }

    @include breakpoint (small down) {
      white-space: normal;
    }
  }

  .terms-and-conditions label::before {
    top: -4px;
  }

  .price-row {
    font-size: 16px;
    margin-top: 8px;
    width: 100%;
    text-align: left;

    span {
      font-size: 20px;
      font-weight: bold;
      display: inline-block;
    }
  }

  .monthly-price {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-top: 8px;
    background-color: #fff;
    border: 1px solid $black;
    margin-left: auto;
    margin-right: auto;

    p {
      color: $black;
      font-size: 16px;
      font-weight: bold;
    }

    &.single-month {
      border-color: #fff;

      p {
        color: #fff;
      }
    }
  }

  .total-price {
    span {
      font-size: 40px;
      font-weight: bold;
      margin-right: 8px;
      display: inline-block;
    }

    font-size: 50px;
    line-height: 80px;
    margin-top: 40px;
    font-weight: bold;
  }

  .vat-excluded {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    top: -24px;
  }

  .btn:not(:first-of-type):not(:last-of-type) {
    margin-right: 16px;
  }
}