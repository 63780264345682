.checkout-wrapper {
  margin: 95px 0 70px;
}

.checkout-title {
  @include garamond-semibold-italic;
  font-size: 40px;
  font-weight: 100;
  margin: 95px 0 30px;
  padding: 15px 0 7px;
  text-align: center;
  border-bottom: 1px solid #e3e6ea;
}

.checkout-step {
  border-bottom: 1px solid $line-color;
  padding: 0 0 7px 40px;
  margin: 15px 0 20px;
  position: relative;
  letter-spacing: 0.01em;

    &::before {
      @include nexa-bold;
      background-color: $black;
      width: 30px;
      height: 30px;
      /*border-radius: 16px;*/
      position: absolute;
      left: 0;
      top: -1px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      padding: 1px 0 0 2px;
      color: $light-blue;
      /*border: 1px solid $light-blue;*/
      font-size: 14px;
    }

    &.first::before {
      content: "1";
      color:white;
    }
    &.second::before {
      content: "2";
      color:white;
    }
    &.third::before {
      content: "3";
      color:white;
    }

    &.active::before {
      background-color: $light-blue;
      color: white;
    }
}

.checkout-buttons {
  margin: 0 0 40px;

    .btn {
      @include breakpoint(small down) {
        margin-bottom: 10px;
      }
    }
}