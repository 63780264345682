.alert-box {
  text-align: center;
  z-index: 1000;
  width: 100%;
  @include breakpoint(small down) {
    bottom: auto;
    top: 0px;
    position: fixed;
  }

  .alert {
    @include nexa-bold;
    padding: 10px 0;
    font-size: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: white;
    animation-name: alert;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .alert-danger {
    background-color: $red;
  }

  .alert-success {
    background-color: $light-blue;
  }
}

@keyframes alert {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}