// colors
$black: #2B2B2B;
$black-hover: #191919;
$gray: #F9F9F9;
$light-blue: #7ACEFA;
$light-blue-hover: #55BAEF;
$line-color: #e3e6ea;
$text-gray: #a9acb5;
$red: #f02c2d;
$green: #35c335;

// Slick slider
$slick-font-path: '/skins/bonbon/fonts/slick/';
$slick-loader-path: '/skins/bonbon/images/slick/';