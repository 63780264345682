@mixin nexa-light {
  font-family: 'Nexa', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
}

@mixin nexa-bold {
  font-family: 'Nexa', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
}

@mixin garamond-semibold-italic {
  font-family: 'Cormorant Garamond', Georgia, serif;
  font-style: italic;
  font-weight: 200;
}

@mixin garamond-regular-italic {
  font-family: 'Cormorant Garamond', Georgia, serif;
  font-style: italic;
  font-weight: 400;
}

@mixin transition-basic {
  transition: all .15s ease-in-out;
}